
.snb {
    transform: translateY(-40px);

    .v-list {
        margin-top: 12px;
        padding: 20px 0;
        border-top: 3px solid var(--v-primary-base);
        // > .v-list-item,
        // > .v-list-group {
        //     margin-bottom: 30px;
        //     &:last-child {
        //         margin-bottom: 0;
        //     }
        // }
    }
}

::v-deep() {
    .v-list-group--sub-group .v-list-group__header,
    .v-list-item {
        padding: 0;

        &::before {
            background-color: initial;
        }

        &--active {
            .v-list-item__title {
                font-weight: 700;
            }
        }
    }
    .active .v-list-item__title {
        font-weight: 700;
    }
    .v-list-group--sub-group {
        .v-list-item__icon:first-child {
            order: 1;
            margin-right: 0;
        }
        .v-list-group__items .v-list-item {
            padding-left: var(--container-gutter);
        }
    }
}
